import XLSX, {WorkBook} from 'xlsx';
import _ from 'lodash';
import {Cell, Excel, Sheet} from "@/domain/model/excel/excel.model";

const COL_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'];

const colLetter2index = (colLetter: string): number => COL_LETTERS.indexOf(colLetter);

const cellname2boundary = (cellname: string): { row: string; col: string } => {
    const boundary: any = cellname.split(/([0-9]+)/);
    return {col: boundary[0], row: boundary[1]};
};

const cellname2cordinates = (cellname: string): { x: number; y: number } => {
    const boundary = cellname2boundary(cellname);

    return {
        x: colLetter2index(boundary.col),
        y: parseInt(boundary.row) - 1,
    };
}

const xlsxcell2cell = (xlsxCell: any, key: string): Cell => {
    const boundary = cellname2boundary(key);

    return {
        name: key,
        col: boundary.col,
        row: boundary.row,
        value: xlsxCell.v
    };
};

const workbook2Excel = (workbook: WorkBook): Excel => {
    const excel: Excel = {
        sheetNames: [...workbook.SheetNames],
        sheets: []
    };
    excel.sheets = _.transform(workbook.Sheets, (result: Sheet[], xlsxSheet: any, key: string) => {
        const sheet: Sheet = {
            name: key,
            lastRow: '',
            lastCol: '',
            boundary: [],
            cellMatrix: [],
            cells: {},
            cellRows: [],
            toJson: []
        };
        const sheetBoundary = cellname2boundary(xlsxSheet['!ref'].split(':')[1]);


        sheet.lastRow = sheetBoundary.row;
        sheet.lastCol = sheetBoundary.col;
        sheet.boundary = [sheetBoundary.col, sheetBoundary.row];
        sheet.cellMatrix = [...Array(COL_LETTERS.indexOf(sheet.lastCol) + 1)].map(x => Array(parseInt(sheet.lastRow)).fill(null));
        sheet.cellRows = [...Array(parseInt(sheet.lastRow))].map(x => Array(COL_LETTERS.indexOf(sheet.lastCol) + 1).fill(null));

        let cells: Cell[] = _.transform(xlsxSheet, (cells: Cell[], xlsxCell: any, key: string) => {
            if (key.startsWith('!') || key === '!ref' || key === '!margins' || key === '!autofilter' || key === '!merges')
                return;

            const cell = xlsxcell2cell(xlsxCell, key);
            cells.push(cell);
        }, []);

        cells = _.sortBy(cells, (cell: Cell) => colLetter2index(cell.col) * 100000 + parseInt(cell.row));

        cells.forEach((cell) => {
            const coordinates = cellname2cordinates(cell.name);

            if (_.isNaN(coordinates.x) || _.isNaN(coordinates.y))
                return

            sheet.cells[cell.name] = cell;
            sheet.cellMatrix[coordinates.x][coordinates.y] = cell;
            sheet.cellRows[coordinates.y][coordinates.x] = cell;
        });

        sheet.toJson = XLSX.utils.sheet_to_json<Record<string, any>>(xlsxSheet, {raw: true});

        result.push(sheet);

    }, []);

    return excel;
}

export function useExcelImporter() {

    const importExcel = async (file: File | null): Promise<Excel> => {
        const arrayBuffer: ArrayBuffer | undefined = await file?.arrayBuffer();

        if (!arrayBuffer)
            throw new Error('Invalid .xlsx file');

        const workbook = XLSX.read(new Uint8Array(arrayBuffer), {type: "array"});
        return workbook2Excel(workbook);
    };

    return {importExcel};
}




